<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
            @change="updatePerPage"
          />
        </b-col>

        <!-- Filter by Dates -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end"
        >
          <date-picker
            v-model="dates"
            :shortcuts="shortcuts"
            value-type="YYYY-MM-DD"
            format="DD-MM-YYYY"
            range
            @input="searchQuotes"
          />
        </b-col>

        <!-- Filter by Status -->
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end"
        >
          <b-form-select
            v-model="filterStatus"
            :options="statusSelect"
            label="name"
            :clearable="false"
            class="mb-0 pr-0 item-selector-title"
            :placeholder="$t('common.select_item')"
            @input="searchQuotes"
          />
        </b-col>

        <!-- Filter by Mechanic -->
        <b-col
          cols="12"
          md="2"
          class="d-flex align-items-center justify-content-end"
        >
          <b-form-select
            v-model="filterMechanic"
            :options="mechanics"
            label="name"
            :clearable="false"
            class="mb-0 pr-0 item-selector-title"
            :placeholder="$t('common.select_item')"
            @input="searchQuotes"
          />
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="2"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="search"
              class="d-inline-block mr-1"
              :placeholder="$t('common.search')"
              @keyup="searchQuotes"
            />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refInvoiceListTable"
      :items="filterInvoices"
      responsive
      :fields="headers"
      primary-key="id"
      :sort-by.sync="sortBy"
      :per-page="0"
      show-empty
      :empty-text="$t('invoice.no_result')"
      :sort-desc.sync="isSortDirDesc"
      :busy="isBusy"
      :current-page="currentPage"
      class="position-relative rows-responsive"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <!-- Column: Id -->
      <template #head(budget_id)>
        <div
          class="mx-auto"
        >
          {{ $t('budget.code') }}
        </div>
      </template>

      <template #cell(budget_id)="data">
        <b-link
          :to="{
            name: 'drs-budgets-form',
            params: { id: data.item.budget_id },
          }"
          :class="data.item.status === 'CANCELLED' ? 'font-weight-bold text-danger' : 'font-weight-bold '"
        >
          {{ data.item.code }}
        </b-link>
      </template>

      <template #head(status)>
        {{ $t("budget.status") }}
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-avatar
          :id="`invoice-row-${data.item.budget_id}`"
          size="32"
          :variant="
            `light-${
              resolveInvoiceStatusVariantAndIcon(data.item.status).variant
            }`
          "
        >
          <feather-icon
            :icon="resolveInvoiceStatusVariantAndIcon(data.item.status).icon"
          />
        </b-avatar>
        <b-tooltip
          :target="`invoice-row-${data.item.budget_id}`"
          placement="top"
        >
          <p class="mb-0">
            {{ getStatusTranslate(data.item.status) }}
          </p>
          <p class="mb-0">
            Due Date: {{ data.item.budget_updated_date }}
          </p>
        </b-tooltip>
      </template>

      <!-- Column: Client -->
      <template #cell(client)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.customer_name }}
          </span>
          <small>{{ data.item.city }} - {{ data.item.cp }}</small>
        </b-media>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.item.budget_date }}
        </span>
      </template>

      <!-- Column: Total -->
      <template #cell(total)="data">
        <span>€ {{ formatNumberEuro(data.item.total) }}</span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-row>
          <b-button
            :to="{
              name: 'drs-budgets-form',
              params: { id: data.item.budget_id },
              query: { page: $route.query.page || 1 }
            }"
            variant="success"
            class="btn-icon mrbt"
          >
            <feather-icon
              icon="EditIcon"
              size="16"
            />
          </b-button>
          <b-button
            :to="{
              name: 'drs-budgets-preview',
              params: { id: data.item.budget_id },
              query: { page: $route.query.page || 1 }
            }"
            variant="info"
            class="btn-icon mrbt"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
            />
          </b-button>
          <b-button
            v-if="data.item.status === 'PENDING'"
            variant="danger"
            class="btn-icon"
            @click="confirmDeleteBudget(data.item.budget_id)"
          >
            <feather-icon
              icon="TrashIcon"
              size="16"
            />
          </b-button>
        </b-row>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span
            class="text-muted"
          >{{ $t("common.displaying") }}
            {{
              perPage * currentPage > totalItems
                ? totalItems
                : perPage * totalItems
            }}
            {{ $t("common.of") }} {{ totalItems }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-if="renderPagination && !isBusy && currentPage != null && totalItems != 0"
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            @change="page => changePage(page)"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BFormSelect,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/nl'
import store from '@/store'
import dbBudget from '@/models/budget'
import useInvoicesList from './useInvoiceList'

import invoiceStoreModule from '../invoiceStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BPagination,
    BTooltip,
    BFormSelect,
    BSpinner,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      renderPagination: true,
      invoices: [],
      dates: [],
      mechanics: [],
      search: '',
      status: '',
      filterStatus: null,
      filterMechanic: null,
      isBusy: false,
      perPage: 50,
      pageOptions: [25, 50, 100, 500],
      totalItems: null,
      headers: [],
      fields: [
        { key: 'status', sortable: false, class: 'Status' },
        { key: 'budget_id', sortable: false, class: this.$i18n.t('budget.code') },
        {
          key: 'client', label: 'budget.client', sortable: false, class: this.$i18n.t('budget.client'),
        },
        {
          key: 'total', label: 'budget.total', sortable: false, class: this.$i18n.t('budget.total'),
        },
        {
          key: 'issuedDate', label: 'invoice.budget_date', class: this.$i18n.t('invoice.budget_date'),
        },
        {
          key: 'actions', label: 'common.actions', class: this.$i18n.t('common.actions'),
        },
      ],
      statusSelect: [
        { value: null, text: 'Alles laten zien status' },
        { value: 'PENDING', text: 'MEE BEZIG' },
        { value: 'SENT', text: 'VERZONDEN' },
        { value: 'ACCEPTED', text: 'GEACCEPTEERD' },
        { value: 'PROCESSING', text: 'IN AFWACHTING' },
        { value: 'INVOICED', text: 'GEFACTUREERD' },
        { value: 'CANCELLED', text: 'GEANULEERD' },
      ],
    }
  },
  computed: {
    filterInvoices() {
      return this.invoices.filter(
        i => i.customer_name.toLowerCase().includes(this.search.toLowerCase())
          || i.city.toLowerCase().includes(this.search.toLowerCase())
          || i.code.toLowerCase().includes(this.search.toLowerCase())
          || i.budget_date.toLowerCase().includes(this.search.toLowerCase()),
      )
    },
    filterInvoicesByStatus() {
      return this.invoices.filter(i => i.status.toLowerCase().includes(this.status.toLowerCase()))
    },
  },
  watch: {
    currentPage() {
      this.fetch(this.currentPage)
    },
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    if (this.$route.query.dates) this.dates = this.$route.query.dates.split(',')
    if (this.$route.query.status) this.filterStatus = this.$route.query.status

    this.listMechanics()
    if (this.$route.query.page) {
      this.currentPage = Number(this.$route.query.page)
    } else {
      this.currentPage = 1
    }
  },
  beforeUpdate() {
    this.fieldLanguage()
  },
  mounted() {
    this.fieldLanguage()
  },
  methods: {
    changePage(page) {
      this.$router.replace({ query: { page } })
    },
    listMechanics() {
      let userMec = null
      if (this.$store.state.app.system) {
        userMec = this.$store.state.app.system.users_map.MECHANICS
        Object.values(userMec).forEach(val => {
          this.mechanics.push({
            value: Object.keys(val)[0],
            text: Object.values(val)[0],
          })
        })
      }
      if (this.$route.query.mechanic) {
        // eslint-disable-next-line no-return-assign
        this.mechanics.filter(m => (m.text === this.$route.query.mechanic ? this.filterMechanic = m.value : ''))
      }
      this.mechanics.unshift({ value: null, text: 'Alles laten zien mechanics' })
      return this.mechanics
    },
    searchQuotes() {
      if (this.search.length > 3 || this.search.length === 0 || this.filterStatus !== null || this.filterMechanic !== null || this.dates !== null) this.fetch(1)
    },
    fieldLanguage() {
      this.headers = JSON.parse(JSON.stringify(this.fields))
      this.fields.forEach((header, index) => {
        if (header.label) {
          this.headers[index].label = `${this.$i18n.t(this.fields[index].label)}`
        }
      })
    },
    getStatusTranslate(status) {
      const getOptionStatus = Object.values(this.statusSelect)
      const statusDutch = getOptionStatus.find(i => i.value === status)
      return statusDutch.text
    },
    updatePerPage(size) {
      this.perPage = size
      this.fetch(1)
      this.$router.replace({ query: { page: 1 } })
    },
    async fetch(page) {
      const self = this
      self.isBusy = true
      const response = await dbBudget.getAll({
        page,
        perPage: self.perPage,
        paginate: true,
        word: self.search,
        status: self.filterStatus,
        dates: self.dates,
        mechanic: self.filterMechanic,
      })
      self.invoices = response.data
      self.totalItems = response.total
      self.isBusy = false
      self.renderPagination = false
      self.$nextTick(() => {
        self.renderPagination = true
      })
    },
    confirmDeleteBudget(idBudget) {
      this.$bvModal
        .msgBoxConfirm(this.$t('budget.modal.delete_budget_body'), {
          title: this.$t('budget.modal.delete_budget_title'),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('orders.modal.yes'),
          cancelTitle: this.$t('orders.modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) this.deleteBudget(idBudget)
        })
    },
    async deleteBudget(idBudget) {
      await dbBudget.deleteBudget(idBudget)
      this.fetch(Number(this.$route.query.page) || 1)
    },
    formatNumberEuro(number) {
      return Number(number).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    const shortcuts = [
      {
        text: 'Vorige Week',
        onClick() {
          const start = new Date()
          const end = new Date()
          end.setTime(end.getTime() - 6 * 24 * 3600 * 1000)
          const date = [end, start]
          return date
        },
      },
      {
        text: 'Deze Maand',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0)
          // end.setTime(end.getTime() - 30 * 24 * 3600 * 1000)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '1e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '00', 1)
          const lastDay = new Date(now.getFullYear(), '02', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '2e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '03', 1)
          const lastDay = new Date(now.getFullYear(), '05', 30)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '3e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '06', 1)
          const lastDay = new Date(now.getFullYear(), '08', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: '4e Kwartaal',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear(), '09', 1)
          const lastDay = new Date(now.getFullYear(), '11', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
      {
        text: 'Vorig jaar',
        onClick() {
          const now = new Date()
          const firstDay = new Date(now.getFullYear() - 1, '00', 1)
          const lastDay = new Date(now.getFullYear() - 1, '11', 31)
          const date = [firstDay, lastDay]
          return date
        },
      },
    ]

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const statusOptions = ['INVOICED', 'SENT', 'CREATED', 'PENDING', 'CANCELLED', 'ACCEPTED']

    const {
      fetchInvoices,
      tableColumns,
      currentPage,
      dataMeta,
      // searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      // statusFilter,
      refetchData,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    } = useInvoicesList()

    return {
      fetchInvoices,
      tableColumns,
      currentPage,
      dataMeta,
      // searchQuery,
      sortBy,
      isSortDirDesc,
      refInvoiceListTable,
      // statusFilter,
      refetchData,
      statusOptions,
      avatarText,
      shortcuts,
      resolveInvoiceStatusVariantAndIcon,
      resolveClientAvatarVariant,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
